
.section-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: var(--section-title-color);
  min-height: 150px;
  margin-bottom: 20px;
  transition: background-color 1s;
}
.section-title .content {
  border-top: 3px solid var(--section-title-color);
  border-bottom: 3px solid var(--section-title-color);
  margin: 0 20px;
  flex-grow: 1;
  padding: 10px 0;
  font-size: 1.2em;
}
.page:hover .section-title {
  background-color: #fff8ef;
}
.section-title p {
  margin: 0 3px;
}
.section-title .block {
  width: 150px;
  min-width: 150px;
  background: var(--section-title-color);
}
@media screen and (max-width:600px) {
  .section-title .block {
    display: none;
  }
}

.section-overview {
  margin: 40px 80px;
}
@media screen and (max-width:600px) {
  .section-overview {
    margin: 20px;
    box-shadow: none;
  }
}
.section-overview h4 {
  margin: 0;
  margin-bottom: 0.6em;
}