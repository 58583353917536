@import url(./colors.css);
/* @import url(./top-menu.css); */
@import url(./footer.css);

body {
  margin: 0;
  padding: 0;
  padding-bottom: 100px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 300;
  line-height: 1.6em;
  color: var(--text-color);
}

p {
  margin: 0.5em 0;
}

.page {
  padding: 80px 0;
}
@media screen and (max-width:600px) {
  .page {
    padding: 40px 0;
  }
}

.overview {
  margin: 80px;
  border: 1px solid silver;
  border-radius: 5px;
  box-shadow: silver 3px 3px 3px;
  padding: 20px 40px;
  text-align: justify;
  font-size: 1.2em;
}
@media only screen and (max-width:600px) {
  .overview {
    margin: 0;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    padding: 20px;
  }
}