.top-menu {
  color: #555353;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background: rgba(200, 191, 185, .3);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid silver;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  font-weight: 600;
}
.top-menu .logo {
  margin: 10px;
  background: #c8bfb9;
  padding: 0 10px;
  border-radius: 5px;
}
@media screen and (max-width:600px) {
  .top-menu .logo {
    display: none;
  }
}
.top-menu .logo img {
  height: 32px;
  padding: 4px;
}
.top-menu ul {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 5px;
  flex-wrap: wrap;
  padding: 0;
}
.top-menu li.current {
  background: white;
  border: 1px solid #c8bfb9;
  cursor: initial;
}
.top-menu li {
  list-style: none;
  margin: 5px 5px;
  background: #c8bfb9;
  padding: 2px 10px;
  cursor: pointer;
}
.top-menu li a {
  text-decoration: none;
}
.top-menu li a:link, .top-menu li a:visited {
  color: inherit;
}
.top-menu .cta {
  color: #7c2d29;
  background: #fee49a;
  border: 1px solid #c8bfb9;
}
