/* Title */
.title {
  --title-bg: #c8bfb9;
  --title-color: #765e4a;
  margin: 150px 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media only screen and (max-width:600px) {
  .title {
    margin: 80px 0;
  }
}
.title .block {
  width: 260px;
  background: var(--title-bg);
  background-image: url('~/images/davos-title-bg.jpg');
  background-size: 75%;
  background-repeat: no-repeat;
  background-position: center;
}
@media only screen and (max-width:640px) {
  .title .block {
    display: none;
  }
}
.title .content {
  flex-grow: 1;
  margin: 0 40px;
  border-top: 3px solid var(--title-color);
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  border-bottom: 3px solid var(--title-color);
  color: var(--title-color);
}
.title .name h1 {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 2.6em;
  line-height: 1.1em;
  color: #ff6600;
}
.title .name span.name {
  font-weight: 800;
  color: #7c2d29
}
.title .name h1 + h4 {
  font-size: 1.6em;
}
.title .name + span {
  margin-top: 20px;
}
.title h4 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 300;
  font-size: 1.3em;
}
.title .city h2 {
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 1.8em;
}
.title .star {
  content: url("small-star.png");
  width: 16px;
  height: 16px;
}
