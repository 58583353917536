@import url(./colors.css);
@import url(./common.css);
@import url(./topmenu.css);
@import url(./footer.css);
@import url(./title.css);
@import url(./section-title.css);
@import url(./gallery/gallery.css);

/* Header */
header {
  background: url("~/images/davos-night.jpg");
  background-repeat: no-repeat;
  height: 300px;
}
@media screen and (max-width:600px) {
  header {
    height: 200px;
  }
}

/* Gallery */
.gallery {
  background: var(--alt-page-bg);
  --section-title-color: var(--section1);
}

/* Map */
.map {
  --section-title-color: var(--section2);
}
.map .section-title .content {
  color: var(--text-color);
}
#map {
  border: 1px solid var(--section2);
  border-radius: 5px;
  width: 90%;
  height: 65vh;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  display: block;
}
