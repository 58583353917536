const-davos-gallery {
  display: flex;
  padding: 0 20px;
  align-items: center;
  justify-content: space-between;
}
@media screen and (max-width:600px) {
  const-davos-gallery {
    display: grid;
  }
}
const-davos-gallery .ctrl {
  width: 5%;
  cursor: pointer;
  text-align: center;
  width: 2em;
  height: 2em;
  padding: 20px;
}
@media screen and (max-width:600px) {
  const-davos-gallery .ctrl {
    grid-row: 1;
    justify-self: center;
    width: 1em;
    height: 1em;
  }
}
const-davos-gallery .ctrl.prev {
  content: url("./nav-prev.png");
}
const-davos-gallery .ctrl.next {
  content: url("./nav-next.png");
}
const-davos-gallery-img {
  flex-grow: 1;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width:600px) {
  const-davos-gallery-img {
    grid-row: 2;
    grid-column: span 2;
    justify-self: center;
    height: auto;
  }
}
const-davos-gallery-img img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
  object-fit: contain;
  touch-action: none;
}
const-davos-gallery-img img.portrait {
  opacity: 0.5;
}
const-davos-gallery-img .description {
  margin-top: 10px;
  display: block;
}
