:root {
  --menu-bg: #c8bfb9;
  --page-bg: white;
  --alt-page-bg: #c8bfb933;
  --section1: #7c2d29;
  --section2: #fee49a;
  --section3: #9fa55a;
  --section4: #785e4c;
  --section5: #b0c4de;
  --cta-color: #7c2d29;
  --cta-bg: #fee49a;
  --text-color: #6c6867;
}

body {
  color: var(--text-color);
  margin: 0;
  padding: 0 0 100px;
  font-family: Montserrat, sans-serif;
  font-weight: 300;
  line-height: 1.6em;
}

p {
  margin: .5em 0;
}

.page {
  padding: 80px 0;
}

@media screen and (width <= 600px) {
  .page {
    padding: 40px 0;
  }
}

.overview {
  text-align: justify;
  border: 1px solid silver;
  border-radius: 5px;
  margin: 80px;
  padding: 20px 40px;
  font-size: 1.2em;
  box-shadow: 3px 3px 3px silver;
}

@media only screen and (width <= 600px) {
  .overview {
    box-shadow: none;
    border: 0;
    border-radius: 0;
    margin: 0;
    padding: 20px;
  }
}

.top-menu {
  color: #555353;
  background: #c8bfb94d;
  border-bottom: 1px solid silver;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: Montserrat, Arial, Helvetica, sans-serif;
  font-weight: 600;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.top-menu .logo {
  background: #c8bfb9;
  border-radius: 5px;
  margin: 10px;
  padding: 0 10px;
}

@media screen and (width <= 600px) {
  .top-menu .logo {
    display: none;
  }
}

.top-menu .logo img {
  height: 32px;
  padding: 4px;
}

.top-menu ul {
  flex-flow: wrap;
  justify-content: flex-end;
  margin: 5px;
  padding: 0;
  display: flex;
}

.top-menu li.current {
  cursor: initial;
  background: #fff;
  border: 1px solid #c8bfb9;
}

.top-menu li {
  cursor: pointer;
  background: #c8bfb9;
  margin: 5px;
  padding: 2px 10px;
  list-style: none;
}

.top-menu li a {
  text-decoration: none;
}

.top-menu li a:link, .top-menu li a:visited {
  color: inherit;
}

.top-menu .cta {
  color: #7c2d29;
  background: #fee49a;
  border: 1px solid #c8bfb9;
}

footer {
  text-align: center;
  color: #555353;
  background: #c8bfb9cc;
  border-top: 1px solid silver;
  margin-top: 10px;
  padding: 10px;
  font-family: Montserrat, Arial, Helvetica, sans-serif;
  font-weight: 400;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

footer b.delimiter {
  margin: 0 20px;
}

footer a:link, footer a:visited {
  color: var(--cta-color);
}

.title {
  --title-bg: #c8bfb9;
  --title-color: #765e4a;
  flex-direction: row;
  justify-content: space-between;
  margin: 150px 0;
  padding: 0;
  display: flex;
}

@media only screen and (width <= 600px) {
  .title {
    margin: 80px 0;
  }
}

.title .block {
  background: var(--title-bg);
  background-image: url("davos-title-bg.f0f43b10.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 75%;
  width: 260px;
}

@media only screen and (width <= 640px) {
  .title .block {
    display: none;
  }
}

.title .content {
  border-top: 3px solid var(--title-color);
  text-align: center;
  border-bottom: 3px solid var(--title-color);
  color: var(--title-color);
  background: #fff;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  margin: 0 40px;
  display: flex;
}

.title .name h1 {
  color: #f60;
  margin-bottom: 0;
  font-size: 2.6em;
  font-weight: 600;
  line-height: 1.1em;
}

.title .name span.name {
  color: #7c2d29;
  font-weight: 800;
}

.title .name h1 + h4 {
  font-size: 1.6em;
}

.title .name + span {
  margin-top: 20px;
}

.title h4 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 1.3em;
  font-weight: 300;
}

.title .city h2 {
  margin-bottom: 10px;
  font-size: 1.8em;
  font-weight: 600;
}

.title .star {
  content: url("small-star.a0b128e4.png");
  width: 16px;
  height: 16px;
}

.section-title {
  color: var(--section-title-color);
  flex-direction: row;
  justify-content: space-between;
  min-height: 150px;
  margin-bottom: 20px;
  transition: background-color 1s;
  display: flex;
}

.section-title .content {
  border-top: 3px solid var(--section-title-color);
  border-bottom: 3px solid var(--section-title-color);
  flex-grow: 1;
  margin: 0 20px;
  padding: 10px 0;
  font-size: 1.2em;
}

.page:hover .section-title {
  background-color: #fff8ef;
}

.section-title p {
  margin: 0 3px;
}

.section-title .block {
  background: var(--section-title-color);
  width: 150px;
  min-width: 150px;
}

@media screen and (width <= 600px) {
  .section-title .block {
    display: none;
  }
}

.section-overview {
  margin: 40px 80px;
}

@media screen and (width <= 600px) {
  .section-overview {
    box-shadow: none;
    margin: 20px;
  }
}

.section-overview h4 {
  margin: 0 0 .6em;
}

const-davos-gallery {
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  display: flex;
}

@media screen and (width <= 600px) {
  const-davos-gallery {
    display: grid;
  }
}

const-davos-gallery .ctrl {
  cursor: pointer;
  text-align: center;
  width: 2em;
  height: 2em;
  padding: 20px;
}

@media screen and (width <= 600px) {
  const-davos-gallery .ctrl {
    grid-row: 1;
    justify-self: center;
    width: 1em;
    height: 1em;
  }
}

const-davos-gallery .ctrl.prev {
  content: url("nav-prev.f27e55ca.png");
}

const-davos-gallery .ctrl.next {
  content: url("nav-next.b1ef1409.png");
}

const-davos-gallery-img {
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  height: 70vh;
  display: flex;
}

@media screen and (width <= 600px) {
  const-davos-gallery-img {
    grid-area: 2 / span 2;
    justify-self: center;
    height: auto;
  }
}

const-davos-gallery-img img {
  object-fit: contain;
  touch-action: none;
  border-radius: 10px;
  max-width: 100%;
  max-height: 100%;
}

const-davos-gallery-img img.portrait {
  opacity: .5;
}

const-davos-gallery-img .description {
  margin-top: 10px;
  display: block;
}

header {
  background: url("davos-night.70f560fb.jpg") no-repeat;
  height: 300px;
}

@media screen and (width <= 600px) {
  header {
    height: 200px;
  }
}

.gallery {
  background: var(--alt-page-bg);
  --section-title-color: var(--section1);
}

.map {
  --section-title-color: var(--section2);
}

.map .section-title .content {
  color: var(--text-color);
}

#map {
  border: 1px solid var(--section2);
  border-radius: 5px;
  width: 90%;
  height: 65vh;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
/*# sourceMappingURL=welcome.6b8ea8f0.css.map */
