:root {
  --menu-bg: #c8bfb9;
  --page-bg: white;
  --alt-page-bg: rgba(200, 191, 185, .2);
  --section1: #7c2d29;
  --section2: #fee49a;
  --section3: #9fa55a;
  --section4: #785e4c;
  --section5: #b0c4de;
  --cta-color: #7c2d29;
  --cta-bg: #fee49a;
  --text-color: #6c6867;
}