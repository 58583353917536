/* Footer */
footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid silver;
  margin-top: 10px;
  padding: 10px;
  text-align: center;
  color: rgb(85, 83, 83);
  background: rgba(200, 191, 185, .8);
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  font-weight: 400;
}
footer b.delimiter {
  margin: 0 20px;
}
footer a:link, footer a:visited {
  color: var(--cta-color);
}